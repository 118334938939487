
.MobileOnly {
    display: none;
}

.DesktopOnly {
    display: block;
}

.MainContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 6%;
    padding-top: 0%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.MainHeading {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -1.3px;
    text-align: center;
    margin-bottom: 2rem;
}

.TestimonialsWrapper {
    /* width: calc(100% - 12%); */
    width: 100%;
    margin: 0 auto;
    border-radius: 48px;

    box-sizing: border-box;
    padding: 0% 6%;
}

.TestimonialSlide {
    width: 100%;
    display: flex;
    align-items: stretch;
}

@media (max-width: 600px) {
    .MobileOnly {
        display: block;
    }

    .DesktopOnly {
        display: none;
    }

    .MainContainer {
        padding: 0;
        padding-top: 2rem;
    }

    .MainHeading {
        font-size: 2rem;
        margin: 0 0 1rem;
    }
    
    .TestimonialsWrapper {
        border-radius: 24px;
        padding: 0 4%;
    }
}