.TestimonialWrapper {
    box-sizing: border-box;
    padding: 0.5rem;
    flex: 1 1 0px;
}

.TestimonialItem {
    width: 100%;
    height: 100%;
    min-height: 305px;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0 0 4px #ccc;
    border-radius: 4px;
    padding: 1rem;
    border-top: 4px solid var(--primary-color);
    transition: all 0.3s;
    user-select: none;
    margin-bottom: 1rem;
}

.TestimonialItem:hover {
    box-shadow: 0 0 12px #ccc;
}

.RatingBar {
    width: 100px;
    margin-bottom: 0.5rem;
}

.TestimonialText {
    font-size: 1rem;
    margin: 0;
    line-height: 1.3;
}

.TestimonialUser {
    font-size: 14px;
    margin: 8px 0 0;
}

@media (max-width: 600px) {
    .TestimonialItem {
        height: 100%;
        flex-wrap: wrap;
        overflow: hidden;
    }

    .TestimonialText {
        font-size: 1rem;
    }
}