.MobileOnly {
    display: none;
}

.DesktopOnly {
    display: block;
}

.MainContainer {
    width: 100%;
    box-sizing: border-box;
    padding-top: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.MainHeading {
    font-size: 48px;
    font-weight: 500;
    letter-spacing: -1.3px;
    color: rgba(0, 0, 0, 0.9);
}

.Illustration {
    width: 100%;
    max-width: 500px;
    margin-top: 2rem;
}

@media (max-width: 600px) {
    .MobileOnly {
        display: block;
    }

    .DesktopOnly {
        display: none;
    }

    .MainContainer {
        padding: 0;
    }
    
    .MainHeading {
        font-size: 28px;
        margin-top: 60px;
    }
}