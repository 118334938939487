.MainContainer {
    width: 100vw;
    height: 100vh;

    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 15;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Overlay {
    width: 100%;
    height: 100vh;

    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;

    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.ContentWrapper {
    width: 100%;
    max-width: 400px;
    margin: 0 16px;

    padding: 24px;
    border-radius: 24px;

    background-color: white;
    z-index: 10;

    position: relative;
}

.CloseIcon {
    position: absolute;

    top: 24px;
    right: 24px;

    cursor: pointer;
    font-size: 24px;
}