.MainContainer {
    position: relative;
}

.FloatingChat {
    position: fixed;
    bottom: 2rem;
    right: 1rem;
    z-index: 10;
}

.WhatsAppIcon {
    width: 64px;
}