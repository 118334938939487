.MobileOnly {
    display: none;
}

.DesktopOnly {
    display: block;
}

.MainContainer {
    width: 100%;
    box-sizing: border-box;
    padding: 6%;
    padding-top: 0;
}

.SectionWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.StepWrapper {
    width: 50%;
}

.FormWrapper {
    width: 50%;
    max-width: 550px;
    margin-left: auto;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 48px;

    box-sizing: border-box;
    padding: 4% 3%;
    
    border-radius: 4px;
    background-color: white;
    box-shadow: 0 0 4px #ccc;
}

.MainHeading {
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: -1.3px;
    color: rgba(0, 0, 0, 0.9);
    text-align: center;
}

.FormHeading {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.4px;
    color: rgba(0, 0, 0, 0.9);
    margin: 0 0 8px 0;
}

.PaymentInfo {
    margin-top: 0;
    margin-bottom: 2rem;
}

.InputBox {
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 12px;
    font-size: 14px;
    border-radius: 8px;
    margin: 16px 0;
    border: 0;
    outline: none;
    background-color: white;
    box-sizing: border-box;
}

.ButtonWrapper {
    margin-top: 24px;
}

.Price {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: -0.75rem;
}

.MPRWrapper {
    font-size: 1rem;
    font-weight: 400;
    color: #999;
    margin-left: 0.5rem;
}

.MRP {
    text-decoration: line-through;
}

.Discount {
    margin-left: 0.5rem;
    font-weight: 500;
    color: rgb(234, 106, 106);
}

.VerticalDivider {
    width: 1px;
    height: 20px;
    background-color: #000;
    margin: 0 1rem;
}

.AdditionalStats {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    margin-top: 0rem;
    font-size: 1rem;
}

@media (max-width: 600px) {
    .MobileOnly {
        display: block;
    }

    .DesktopOnly {
        display: none;
    }

    .MainContainer {
        padding: 0;
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .SectionWrapper {
        flex-direction: column;
    }

    .MainHeading {
        font-size: 2rem;
        margin-top: 60px;
    }

    .StepWrapper {
        width: 100%;
    }

    .FormWrapper {
        width: 100%;
        max-width: 100%;
        margin-top: 32px;
        padding: 24px;
        border-radius: 4px;
        background-size: cover;
    }

    .InputBox {
        width: 100%;
        margin-bottom: 16px;
    }

    .Price {
        font-size: 1.2rem;
    }

    .MPRWrapper {
        font-size: 0.75rem;
    }

    .AdditionalStats {
        font-size: 0.75rem;
    }
}