.MainWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 3%;
}

.MediaSection {
    width: 60%;
    max-width: 700px;
    display: flex;
    justify-content: center;
}

.TextSection {
    width: 40%;
    margin-left: 6%;
}

.PreHeading {
    font-size: 16px;
    margin-bottom: 0;
}

.RatingWrapper {
    width: min-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 2rem;
    cursor: pointer;
}

.RatingWrapper > img {
    width: 100px;
    margin-left: 0.5rem;
}

.Rating {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.MainHeading {
    font-size: 3rem;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 0rem;
}

.PreviewImageWrapper {
    max-width: 500px;
    margin: 0 auto;
    padding-left: 1.5rem;
}

.PreviewImage {
    width: 100%;
    border-radius: 4px;
}

.AboutItem span {
    font-weight: 600;
}

.HorizontalDivider {
    width: 100%;
    height: 1px;
    background-color: #ccc;
}

.VerticalDivider {
    width: 1px;
    height: 20px;
    background-color: #000;
    margin: 0 1rem;
}

.OrderButtonWrapper {
    margin: 4rem 0 0;
}

.AdditionalStats {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
}

.MobileOnly {
    display: none;
}

.DesktopOnly {
    display: block;
}

@media (max-width: 600px) {
    .MainWrapper {
        flex-direction: column;
    }

    .MediaSection {
        width: 100%;
        flex-direction: column;
        max-width: 100%;
    }

    .PreviewImageWrapper {
        max-width: 100%;
        margin: 0 auto;
        padding-left: 0;
    }

    .TextSection {
        width: 100%;
        margin-left: 0;
    }

    .MainHeading {
        font-size: 2rem;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 0rem;
    }
    
    .OrderButtonWrapper {
        margin: 2rem 0 0;
    }

    .MobileOnly {
        display: block;
    }

    .DesktopOnly {
        display: none;
    }

    .AdditionalStats {
        font-size: 0.75rem;
    }
}