.Button {
    border: 0;
    background-color: var(--primary-color);
    color: var(--txt-secondary-color);
    padding: 12px 32px;
    border-radius: 8px;
    letter-spacing: 1px;
    font-weight: 500;
    cursor: pointer;
    font-size: 14px;
    transition: box-shadow 0.4s;
}

.FullWidth {
    display: block;
    width: 100%;
}

.Button:hover {
    box-shadow: 0px 0px 12px #ccc;
}

.ButtonIcon {
    font-size: 14px;
    margin-left: 16px;
}