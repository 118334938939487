.IndicatorWrapper {
    width: 200px;
    margin: 0 auto;    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}

.CarouselIndicator {
    width: 12px;
    height: 12px;
    margin: 0 2px;
    background-color: rgb(196, 196, 196);
    border-radius: 50%;
}

.CarouselIndicator.Active {
    background-color: var(--primary-color);
}