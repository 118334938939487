.MobileOnly {
  display: none;
}

.DesktopOnly {
  display: block;
}

.FeatureSectionWrapper {
  position: relative;
  padding-top: 6%;
}

.FeatureItemWrapper {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 2rem;
  margin: 0 auto;
}

.IllustrationWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
}

.Illustration {
  height: 400px;
  margin-top: 4rem;
}

.MainHeading {
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -1.3px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .MobileOnly {
    display: block;
  }

  .DesktopOnly {
    display: none;
  }

  .FeatureSectionWrapper {
    position: relative;
    padding-top: 12%;
  }

  .MainHeading {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .FeatureItemWrapper {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0rem 4% 1rem;
    box-sizing: border-box;
  }

  .IllustrationWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50%;
  }

  .Illustration {
    width: 100%;
    height: auto;
    margin-top: 0rem;
  }
}
