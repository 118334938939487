.MainContainer {
  width: 100%;
  background-color: white;
  box-sizing: border-box;
  padding: 8px 8%;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.TopbarShadow {
  box-shadow: 0 0px 4px #ccc;
}

.LogoWrapper {
  height: 45px;
}

.Logo {
  height: 45px;
}

.MenuWrapper {
  display: flex;
  align-items: center;
}

.MenuItem {
  font-size: 14px;
  font-weight: 500;
  color: var(--txt-primary-color);
  margin: 0 14px;
}

.Dropdown {
  position: relative;
}

.DropdownWrapper {
  min-width: 250px;
  display: none;
  position: absolute;
  top: 24px;
  right: 0;
  background-color: white;
  box-shadow: 0px 0px 12px #ccc;
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
  border-radius: 8px;
}

.DropdownWrapper .MenuItem {
  margin: 0;
  display: block;
}

.DropdownWrapper .MenuItem:hover {
  text-decoration: underline;
}

.Dropdown:hover .DropdownWrapper {
  display: block;
}

.BtnGetQuote {
  margin-left: 32px;
  background-color: white;
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 12px 32px;
  border-radius: 8px;
  letter-spacing: 1px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
}

.BtnGetQuote:hover {
  background-color: var(--primary-color);
  color: white;
}

@media (max-width: 600px) {
  .MainContainer {
    padding: 8px 4%;
  }

  .Logo {
    height: 45px;
  }

  .MenuItem,
  .Dropdown {
    display: none;
  }

  .BtnGetQuote {
    display: inline-block;
    padding: 5px 16px;
    font-size: 12px;
  }
}
