.MobileOnly {
    display: none;
}

.DesktopOnly {
    display: block;
}

.FeatureItem {
    max-width: 300px;
    margin-bottom: 6rem;
}

.Heading {
    margin: 1rem 0;
}

.Description {
    margin-top: 0;
}

.Icon {
    width: 40px;
}

@media (max-width: 600px) {
    .MobileOnly {
        display: block;
    }

    .DesktopOnly {
        display: none;
    }

    .FeatureItem {
        max-width: 100%;
        margin-bottom: 2rem;
    }
}