.Footer {
  width: 100%;
  padding: 24px 8%;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 1);
  color: rgba(255, 255, 255, 0.9);
}

.FooterTopSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FooterLink {
  color: #fff;
  margin-right: 1.5rem;
}

.FooterLinkWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 0;
  font-size: 14px;
}

.LoveText i {
  margin-left: 6px;
  margin-right: 6px;
  color: rgb(179, 32, 32);
}

.ContactInfoWrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  color: #fff;
  text-align: right;
}

.ContactInfo {
  margin: 0;
  font-size: 14px;
}

.ContactInfo > i {
  font-size: 14px;
  margin: 0 4px 0 0;
  display: inline-block;
}

.Phone {
  margin-left: 2rem;
}

.CorporateAddress {
  font-size: 14px;
  margin-top: 1rem;
  padding-top: 1rem;
  text-align: center;
  border-top: 1px solid #ccc;
}

@media (max-width: 600px) {
  .Footer {
    padding: 16px;
  }

  .FooterTopSection {
    flex-direction: column;
    align-items: flex-start;
  }

  .ContactInfo {
    margin-top: 0px;
  }

  .FooterLinkWrapper,
  .ContactInfoWrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .ContactInfoWrapper {
    margin-top: 1rem;
  }

  .Phone {
    margin-left: 0;
  }
}
