.BannerCarousel {
    width: 100%;
    display: flex;
}
.ThumbnailWrapper {
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
}
.Thumbnail {
    width: 80px;
    margin: 0.5rem 0;
    border: 4px solid transparent;
    border-radius: 4px;
}
.Thumbnail:first-of-type {
    margin-top: 0;
}
.SelectedThumbnail {
    border-color: var(--primary-color);
}
@media (max-width: 600px) {
    .Thumbnail {
        width: 40px;
        border-width: 3px;
    }
}