.MainHeading {
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: -1.3px;
  color: rgba(0, 0, 0, 0.9);
  text-align: center;
  margin-bottom: 2rem;
}

.ProductGrid {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProductCardWrapper {
  width: calc(25% - 2rem);
  margin: 1rem;
  box-sizing: border-box;
  background-color: white;
  box-shadow: 0 0 12px #ccc;
  border-radius: 6px;
}

.ProductImage {
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.ProductName {
  margin: 0;
  font-weight: 500;
  padding: 1rem;
  font-size: 15px;
  box-sizing: border-box;
  text-align: center;
}

@media (max-width: 600px) {
  .MainHeading {
    font-size: 2rem;
    margin-bottom: 0;
  }

  .ProductGrid {
    flex-direction: column;
  }

  .ProductCardWrapper {
    width: calc(100% - 2rem);
  }
}
